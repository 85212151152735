<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body class="mt-md-1">
        <b-overlay :show="isLoading" rounded="sm">
          <div class="m-1">
            <b-row class="mt-1">
              <b-col cols="12">
                <vue-good-table
                    mode="remote"
                    class="my-table"
                    row-style-class="vgt-row"
                    styleClass="vgt-table striped bordered"
                    :columns="columns"
                    :rows="mainData"
                    :pagination-options="paginationOptions"
                    :total-rows="totalRows"
                    :fixed-header="true"
                    :select-options="{ enabled: true, selectOnCheckboxOnly: true, selectionText: 'Môn học được chọn', clearSelectionText: 'Bỏ chọn' }"
                    @on-selected-rows-change="selectionChanged"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                >
                  <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                      slot="table-row"
                      slot-scope="props"
                  >
                    <span v-if="props.column.field === 'totalCreditNum'">
                      {{ showCreditSummary(props.row) }}
                    </span>
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                      slot="pagination-bottom"
                      slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                            v-model="filter.itemsPerPage"
                            :options="itemsPerPageOptions"
                            class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                            :value="1"
                            :total-rows="totalRows"
                            :per-page="filter.itemsPerPage"
                            class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="text-center mt-1">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="ml-1"
                    @click="onSubmitData"
                >
                    <span class="text-nowrap text-right d-none d-lg-block">
                      <feather-icon icon="PlusIcon" /> Thêm các môn học đã chọn vào danh sách thay thế KLTN
                    </span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'ReplaceThesisSubjects',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  props: {
    extData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 20,
        programmeId: this.extData.parentId,
        notRegistered: 1,
      },
      selectedMainData: [],
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      mainData: 'programmeReplaceThesisSubject/dataLists',
      totalRows: 'programmeReplaceThesisSubject/totalRows',
      resourceName: 'programmeReplaceThesisSubject/resourceName',
    }),
    columns() {
      return [
        {
          label: '#',
          field: 'rowNum',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: `Mã ${this.resourceName.shortName}`,
          field: 'code',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: `Tên ${this.resourceName.shortName}`,
          field: 'name',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tổng TC (LT_TH_TT)',
          field: 'totalCreditNum',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        // {
        //   label: 'HP thay thế',
        //   field: 'alterCode',
        //   filterOptions: {
        //     enabled: false,
        //     trigger: 'enter',
        //   },
        //   sortable: true,
        //   thClass: 'text-center',
        // },
        // {
        //   label: 'HP điều kiện',
        //   field: 'conditionCode',
        //   filterOptions: {
        //     enabled: false,
        //     trigger: 'enter',
        //   },
        //   sortable: true,
        //   thClass: 'text-center',
        // },
        // {
        //   label: 'Học kỳ (dự kiến)',
        //   field: 'semesterName',
        //   filterOptions: {
        //     enabled: false,
        //     trigger: 'enter',
        //     filterDropdownItems: this.semesterFilterDropdownItems,
        //     placeholder: 'Tất cả',
        //   },
        //   width: '100px',
        //   sortable: false,
        //   thClass: 'text-center',
        // },
      ]
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getMainData(this.filter)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: 'programmeReplaceThesisSubject/getData',
      updateMainData: 'programmeReplaceThesisSubject/updateData',
    }),
    async onSubmitData() {
      if (this.selectedMainData.length < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn môn học!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const request = {
          programmeId: this.extData.parentId,
          programmeSubjectIds: this.selectedMainData,
        }
        const response = await this.updateMainData(request)
        if (response) {
          const { isSuccessful, message, data } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$emit('succeed', {})
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message + ((data[0]) ? `: ${data[0]}` : ''),
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
      // this.$emit('success')
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      if (Object.hasOwn(columnFilters, 'groupCode')) this.updateParams({ groupId: columnFilters.groupCode })
      if (Object.hasOwn(columnFilters, 'semesterName')) this.updateParams({ courseSemesterId: columnFilters.semesterName })
      await this.getMainDataFromStore()
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
    showCreditSummary(row) {
      const summary = `${row.totalCreditNum} (${
        row.theoryCreditNum}_${
        row.practiceCreditNum}_${
        row.internshipCreditNum})`
      return summary
    },
    selectionChanged(params) {
      this.selectedMainData = params ? params.selectedRows.map(e => ({ programmeSubjectId: e.id })) : []
    },
  },
}
</script>
